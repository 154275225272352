<template>
    <div>
        <div class="commonInputTopBox">
            <pageHeader :pagename="'产品类目管理'" :line="true" />
            <CommonHeaders
                :formConfig="formConfig"
                :formModel="formModel"
                :buttons="buttons"
                @button-click="handleButtonClick"
            ></CommonHeaders>
        </div>
        <div class="commonControl-body">
            <common-table
                :tableData="tableData"
                :tableButton="tableButton"
                :tableLabel="tableLabel"
                @edit="editFirstLevel"
            ></common-table>
            <div class="layoutBorder">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="query.currentPage"
                    :page-sizes="[5, 10, 20, 40]"
                    :page-size="query.pagesize"
                    layout="total, sizes, prev, pager, next, jumper"
                >
                </el-pagination>
            </div>
        </div>

        <!-- 点击事件触发 -->
        <el-dialog title="编辑产品类目" :visible.sync="dialogFormVisibleEdit">
            <el-form :model="formEdit">
                <el-form-item label="产品分类id" label-width="120px">
                    <el-input
                        v-model="formEdit.product_type_id"
                        autocomplete="off"
                        :disabled="true"
                    ></el-input>
                </el-form-item>
                <el-form-item label="产品类型名称" label-width="120px">
                    <el-input
                        v-model="formEdit.product_type_name"
                        autocomplete="off"
                    ></el-input>
                </el-form-item>
                <!-- <el-form-item label="产品类目" label-width="120px">
                    <el-select
                        v-model="formEdit.content_type"
                        placeholder="请选择产品类目"
                    >
                        <el-option label="设备" value="1"></el-option>
                        <el-option label="软件" value="2"></el-option>
                    </el-select>
                </el-form-item> -->
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisibleEdit = false"
                    >取 消</el-button
                >
                <el-button type="primary" @click="updateFirstLevel()"
                    >确 定</el-button
                >
            </div>
        </el-dialog>

        <!-- 新增产品弹窗 -->
        <el-dialog title="新增产品类目" :visible.sync="dialogFormVisibleAdd">
            <el-form :model="formAdd">
                <el-form-item label="产品类目名称" label-width="120px">
                    <el-input
                        v-model="formAdd.product_type_name"
                        autocomplete="off"
                    ></el-input>
                </el-form-item>
                <el-form-item label="产品类目" label-width="120px">
                    <el-select
                        v-model="formAdd.content_type"
                        placeholder="请选择产品类目"
                    >
                        <el-option label="设备" value="1"></el-option>
                        <el-option label="软件" value="2"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisibleAdd = false"
                    >取 消</el-button
                >
                <el-button type="primary" @click="createFirstLevel()"
                    >确 定</el-button
                >
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {
    product_classify_get,
    product_classify_create,
    product_classify_edit,
    product_classify_delete
} from '@/api/manage/operation/product/product.js';
import CommonTable from 'frontelementpackage/src/CommonTableButtonFixed.vue';
import CommonHeaders from '@/components/shibo_Common/CommonHeaders.vue';
export default {
    name: 'start',
    data() {
        return {
            //分页数据
            query: {
                total: 0,
                currentPage: 1, //初始页
                querypage: 1, //获取页码
                pagesize: 10, //每页返回数量
                keyword: '' //搜索 可搜邮箱 用户名 手机号
            },
            tableData: [],
            dialogFormVisibleEdit: false,
            formEdit: { group_info: '' },
            dialogFormVisibleAdd: false,
            formAdd: {},
            tableLabel: [
                {
                    prop: 'id',
                    label: 'id',
                    minWidth: '50%'
                },
                {
                    prop: 'content_type',
                    label: '产品类型',
                    minWidth: '50%'
                },
                {
                    prop: 'product_type_name',
                    label: '产品类目名称',
                    minWidth: '50%'
                }
            ],
            tableButton: {
                width: 200,
                data: [
                    {
                        name: '编辑',
                        type: 'primary',
                        size: 'small'
                    },
                    {
                        name: '删除',
                        type: 'danger',
                        size: 'small'
                    }
                ]
            },
            formConfig: [],
            formModel: {},
            buttons: [
                { label: '新增管理分类', type: 'primary', action: 'create' }
            ]
        };
    },
    components: {
        CommonTable,
        CommonHeaders
    },
    created() {
        this.getFirstLevel();
    },
    methods: {
        editFirstLevel(row, methods) {
            if (methods == '编辑') {
                this.editUser(row);
            }
            if (methods == '删除') {
                this.delUser(row);
            }
        },
        editUser(row) {
            this.formEdit = {
                product_type_id: row.id,
                product_type_name: row.product_type_name,
                content_type:row.content_type
            };
            console.log(this.form, 'ddd');
            this.dialogFormVisibleEdit = true;
        },
        delUser(row) {
            let ids = row.id;

            this.$confirm('是否确定删除', '确认信息', {
                distinguishCancelAndClose: true,
                confirmButtonText: '删除',
                cancelButtonText: '放弃删除'
            })
                .then(() => {
                    product_classify_delete({ product_type_id: ids }).then(
                        res => {
                            if (res.code == 200) {
                                this.$message.success('成功删除');
                                window.location.reload();
                            } else {
                                this.$message.info(res.msg);
                            }
                        }
                    );
                })
                .catch(action => {
                    this.$message({
                        type: 'info',
                        message: action === 'cancel' ? '放弃删除' : '停留在当前'
                    });
                });
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            this.query = {
                querypage: this.currentPage,
                pagesize: this.pagesize,
                keyword: ''
            };
            //  console.log("当前页面显示xxx条", this.currentPage,"当前界面是xxx页",this.currentPage);
            // user_group_manage_index(this.query).then((res) => {
            //   console.log(this.tableData, "new");
            //   this.tableData = res.data.result;
            // });
        },
        handleSizeChange(val) {
            this.pagesize = val; //获取page-sizes里的每页显示几条数据的值，赋给我们自定义的每页显示数量的变量pageNum
            this.query = {
                querypage: this.currentPage,
                pagesize: this.pagesize,
                keyword: ''
            };
            console.log(this.query);
            // user_group_manage_index(this.query).then((res) => {
            //   console.log(this.tableData, "new");
            //   this.tableData = res.data.result;
            // });
        },

        createFirstLevel() {
            product_classify_create(this.formAdd).then(res => {
                if (res.code == 200) {
                    this.$message.success('成功添加');
                    this.$router.go(0);
                } else {
                    this.$message.info(res.msg);
                }
            });
            this.dialogFormVisibleAdd = false;
        },
        addFormButton() {
            this.dialogFormVisibleAdd = true;
        },
        updateFirstLevel() {
            console.log(this.formEdit);
            product_classify_edit(this.formEdit).then(res => {
                if (res.code == 200) {
                    this.$message.success('成功修改');
                    this.$router.go(0);
                } else {
                    this.$message.info(res.msg);
                }
            });
            this.dialogFormVisibleEdit = false;
        },
        getFirstLevel() {
            product_classify_get({}).then(res => {
                console.log(this.tableData, 'new');
                this.tableData = res.data;
                this.total = res.data.total;
            });
        },
        handleButtonClick(button) {
            if (button.action === 'create') {
                this.addFormButton();
            }
        }
    }
};
</script>

<style lang="scss" scoped></style>
